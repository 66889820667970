/* import __COLOCATED_TEMPLATE__ from './custom-report-header.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Report from 'embercom/models/reporting/custom/report';
import type IntlService from 'embercom/services/intl';
import type FavoritesService from 'embercom/services/favorites-service';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Chart from 'embercom/models/reporting/custom/chart';
import { type TaskGenerator } from 'ember-concurrency';
import type Router from '@ember/routing/router-service';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type ReportAccessService from 'embercom/services/report-access-service';

interface Args {
  editMode: boolean;
  enableEditMode: (value: boolean) => void;
  sharedAnalyticsData: Record<string, string>;
  report: Report;
  chart: Chart;
  duplicateReport: () => TaskGenerator<void>;
  deleteReport: () => TaskGenerator<void>;
  saveReport: () => TaskGenerator<void>;
  addChart: (buttonPosition: string) => Promise<void>;
  openChartTemplateSidePanel: () => void;
  closeChartTemplateSidePanel: () => void;
  sidePanelIsOpen: boolean;
  cancelChanges: () => void;
  referrer: string;
  hasChanges: () => boolean;
  canEditReportOrReportIsNew: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class ReportingCustomReportCustomReportHeader extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare favoritesService: FavoritesService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service intercomEventService: $TSFixMe;
  @service permissionsService: $TSFixMe;
  @service appService: $TSFixMe;
  @service declare router: Router;
  @service declare customReportsService: CustomReportsService;
  @service declare reportAccessService: ReportAccessService;

  @action
  trackAnalyticsEvent(data: Record<string, string>) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  async addChart() {
    await this.args.addChart('header');
  }

  get confirmCancelText() {
    return {
      title: this.intl.t('reporting.custom-reports.report.cancel-modal.title'),
      primaryButtonType: 'primary',
      confirmButtonText: this.intl.t(
        'reporting.custom-reports.report.cancel-modal.confirm-btn-text',
      ),
      cancelButtonText: this.intl.t('reporting.custom-reports.report.cancel-modal.cancel-btn-text'),
      body: this.intl.t('reporting.custom-reports.report.cancel-modal.body'),
      distinguishCancelFromClose: false,
    };
  }

  get addChartOptions() {
    return [
      {
        items: [
          {
            text: this.intl.t('reporting.custom-reports.report.from-template'),
            onSelectItem: this.args.openChartTemplateSidePanel,
            isDisabled: this.args.sidePanelIsOpen,
          },
          {
            text: this.intl.t('reporting.custom-reports.report.create-new-chart'),
            onSelectItem: this.addChart,
          },
        ],
      },
    ];
  }

  @action
  addOrRemoveFavorite() {
    taskFor(this.favoritesService.addOrRemoveFavorite).perform(this.args.report.id);
  }

  @task({ drop: true })
  *saveReport() {
    yield taskFor(this.args.saveReport).perform();
    this.args.enableEditMode(false);
    this.args.closeChartTemplateSidePanel();
  }

  @action
  navigateToSourceRoute() {
    if (this.args.referrer === this.router.currentURL) {
      //ifs previous route is same as current route take user to overview page.
      this.router.transitionTo('apps.app.reports.overview');
    } else {
      this.router.transitionTo(this.args.referrer);
    }
  }

  reportIsNew(report: Report) {
    return report.isNew as unknown as boolean;
  }

  @action
  async cancel() {
    if (this.args.sidePanelIsOpen) {
      this.args.closeChartTemplateSidePanel();
    }

    if (this.args.report.isNewAndUnChanged) {
      this.args.cancelChanges();
      this.navigateToSourceRoute();
    } else if (this.args.hasChanges()) {
      let confirmed: boolean = await this.intercomConfirmService.confirm(this.confirmCancelText);
      if (confirmed && !this.reportIsNew(this.args.report)) {
        this.args.cancelChanges();
        this.args.enableEditMode(false);
      } else if (confirmed && this.reportIsNew(this.args.report)) {
        this.args.cancelChanges();
        this.navigateToSourceRoute();
      }
    } else {
      this.args.enableEditMode(false);
    }
  }

  get favouriteItem() {
    if (this.favoritesService.isFavoriteReport(this.args.report.id)) {
      return {
        text: this.intl.t('reporting.custom-reports.report.remove-from-favorites'),
        icon: 'relationship',
        onSelectItem: this.addOrRemoveFavorite,
      };
    }
    return {
      text: this.intl.t('reporting.custom-reports.report.add-to-favorites'),
      icon: 'relationship-light',
      onSelectItem: this.addOrRemoveFavorite,
    };
  }

  get actionItems() {
    return [
      {
        items: [
          this.favouriteItem,
          {
            text: this.intl.t('reporting.custom-reports.report.duplicate'),
            icon: 'duplicate',
          },
          {
            text: this.intl.t('reporting.custom-reports.report.delete'),
            icon: 'trash',
            isDestructive: true,
          },
        ],
      },
    ];
  }

  private get canEdit() {
    return this.customReportsService.canChangeCustomReports;
  }

  @action
  onClickEditReport() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      clicked_property: 'edit_btn',
      object: 'custom_report_2',
      has_edit_permission: this.canEdit,
      section: 'reports',
    });

    if (this.canEdit) {
      this.args.enableEditMode(true);
      if (!this.appService.app.canSeeR2UiUpdates) {
        this.args.openChartTemplateSidePanel();
      }
    } else {
      this.customReportsService.loadChangePermissionModal();
    }
  }

  get updatedAt(): Date {
    return this.args.report.updatedAt;
  }

  get disableSave() {
    //disable save if report is dirty
    return !this.args.hasChanges();
  }

  get editReportDisabled() {
    if (this.appService.app.canShareReportsInternally) {
      return !this.reportAccessService.adminHasEditAccess(this.args.report);
    } else {
      return false;
    }
  }

  get canViewReport() {
    if (this.appService.app.canShareReportsInternally) {
      return this.reportAccessService.adminHasAccess(this.args.report);
    } else {
      return true;
    }
  }

  @action
  handleEditRevoke() {
    if (this.canViewReport) {
      this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {
        this.args.enableEditMode(false);
        this.args.closeChartTemplateSidePanel();
      });
    } else {
      this.router.transitionTo('apps.app.reports.overview');
      this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {});
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::CustomReportHeader': typeof ReportingCustomReportCustomReportHeader;
    'reporting/custom/report/custom-report-header': typeof ReportingCustomReportCustomReportHeader;
  }
}
