/* import __COLOCATED_TEMPLATE__ from './add-chart-side-panel.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { elementIsVisibleOnScreen } from 'embercom/lib/screen-awareness';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  report: Report;
  addChart: (buttonPosition: string) => Promise<void>;
}

export default class AddChartSidePanel extends Component<Args> {
  @service declare reportingTemplates: ReportingTemplates;
  @service declare intl: IntlService;

  @tracked searchTerm = '';
  @tracked isHidden = false;
  @tracked selectedChartType = 'any';
  @tracked selectedReportType = 'any';

  get filteredTemplates() {
    return this.reportingTemplates.searchChartsWithFilters(
      this.searchTerm,
      this.selectedChartType,
      this.selectedReportType,
    );
  }

  get emptyTemplates() {
    return this.filteredTemplates.every((report: any) => {
      return report.chartTemplates.length === 0;
    });
  }

  @action
  clearFilters() {
    this.searchTerm = '';
    this.selectedChartType = 'any';
    this.selectedReportType = 'any';
  }

  @action
  checkCreateChartCard() {
    debounce(this, this.isCreateChartVisible, ENV.APP._100MS);
  }

  isCreateChartVisible() {
    let element = document.getElementById('create-new-chart-card');
    this.isHidden = !elementIsVisibleOnScreen(element);
  }

  @action
  async createNewChart() {
    await this.args.addChart('chart-template-side-panel');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::AddChartSidePanel': typeof AddChartSidePanel;
    'reporting/custom/report/add-chart-side-drawer': typeof AddChartSidePanel;
  }
}
