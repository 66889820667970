/* import __COLOCATED_TEMPLATE__ from './report-template-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { REPORT_TEMPLATES } from 'embercom/lib/reporting/custom/report-template-helper';

export default class ReportTemplateModal extends Component {
  @service router;
  @service store;
  @service permissionsService;
  @service intercomEventService;
  @service appService;
  @service customReportsService;

  @tracked report;
  @tracked templateKey;
  @tracked width;

  constructor() {
    super(...arguments);
    this.report = this.store.createRecord('reporting/custom/report');
    this.templateKey = this.args.templateKey || null;
    this.width = this.widthForState;
  }

  get reportTemplates() {
    if (!this.appService.app.hasCustomReportsBillingFeature) {
      return [];
    }

    return Object.values(REPORT_TEMPLATES);
  }

  get emptyCharts() {
    let sharedCharts = [
      {
        icon: 'team',
        metricId: 'teammate.time_away',
        viewBy: 'reason',
        metricIsNew: 'true',
      },
      {
        icon: 'conversation',
        metricId: 'v1.new_conversations',
        viewBy: 'time',
      },
      {
        icon: 'conversation-rating',
        metricId: 'conversation_rating.human.csat',
        viewBy: 'conversation_tag_ids',
      },
    ].compact();

    if (this.appService.app.canUseFeature('self-serve-support-fin-experience')) {
      sharedCharts.push({
        icon: 'fin',
        metricId: 'fin.unresolved_conversations.count',
        viewBy: this.appService.app.hasConversationalInsightsBillingFeature ? 'topic' : 'time',
        metricIsNew: 'true',
      });
    } else {
      sharedCharts.push({
        icon: 'delivery-window',
        metricId: 'v1.ticket_time_to_resolve',
        viewBy: 'time',
      });
    }
    return sharedCharts;
  }

  get sharedAnalyticsData() {
    return {
      object: 'custom_report',
      custom_report_id: this.report.id,
      custom_report_name: this.report.title || 'untitled',
    };
  }

  get widthForState() {
    return this.templateKey ? 'vw95' : 'xl';
  }

  @action
  trackAnalyticsEvent(data) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  closeReportTemplateModal() {
    // don't reset the templateKey if it was passed down, e.g. from the team performance report
    if (!this.args.templateKey) {
      this.templateKey = null;
    }
    this.width = this.widthForState;
    this.report.unloadRecord(); // unload and recreate the report so that it's not cached locally
    this.report = this.store.createRecord('reporting/custom/report');
    this.args.closeModal();
  }

  @action
  async saveReportTemplate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'template_report_saved',
      object: 'report_template_modal',
      template_key: this.args.templateKey,
    });
    let promises = [];

    if (this.args.report) {
      this.args.report.charts = this.report.charts;
      this.args.report.charts.forEach((chart) => {
        promises.push(chart.save());
      });
      await Promise.all(promises);
      this.args.report.title = this.args.report.title || this.report.title;
      this.args.report.filters = this.report.filters;
      await this.args.report.save();
      this.args.closeModal();
      this.report.rollbackAttributes();
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', this.args.report.id);
    } else {
      this.report.charts.forEach((chart) => {
        promises.push(chart.save());
      });
      await Promise.all(promises);
      let newReport = await this.report.save();
      this.args.closeModal();
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', newReport.id);
    }
  }

  @action
  addEmptyChart() {
    this.router.transitionTo(
      `apps.app.reports.custom-reports.report.${this.args.report.isNew ? 'new' : 'show'}.chart.new`,
    );
  }

  @action
  useTemplateReport(templateKey) {
    this.templateKey = templateKey;
    this.width = this.widthForState;
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }
}
