/* import __COLOCATED_TEMPLATE__ from './conditions-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type Condition from 'embercom/models/conversation-attributes/condition';
import type ListOption from 'embercom/models/conversation-attributes/list-option';

interface Args {
  targetAttribute: ConversationAttributeDescriptor;
  allAttributes: ConversationAttributeDescriptor[];
  onClose: () => void;
  onSave: () => void;
}

export default class ConditionsSideDrawer extends Component<Args> {
  @service store: any;

  get availableControllingAttributes() {
    return this.args.allAttributes
      .rejectBy('archived')
      .rejectBy('id', this.args.targetAttribute.id)
      .filterBy('dataType', 'list')
      .map((attribute) => {
        return { text: attribute.displayName, value: attribute.id };
      });
  }

  get visibleConditions() {
    return this.args.targetAttribute.conditions.filterBy('isDeleted', false);
  }

  get isInvalid() {
    return this.args.targetAttribute.conditions.any((condition: Condition) => {
      return !condition.controllingDescriptorId || !condition.controllingListOptionId;
    });
  }

  get canAddCondition() {
    return this.visibleConditions.length < 100;
  }

  @action onAddCondition() {
    this.store.createRecord('conversation-attributes/condition', {
      controllingDescriptorId: undefined,
      controllingListOptionId: undefined,
      descriptorListOptionIds: [],
      descriptorId: this.args.targetAttribute,
    });
  }

  @action onRemoveCondition(index: number) {
    this.args.targetAttribute.conditions.objectAt(index).deleteRecord();
    this.args.targetAttribute.conditions = this.args.targetAttribute.conditions;
  }

  @action onSelectControllingAttribute(index: number, value: string) {
    this.args.targetAttribute.conditions.objectAt(index).controllingDescriptorId = value;
    this.args.targetAttribute.conditions.objectAt(index).controllingListOptionId = undefined;
  }

  @action onSelectControllingAttributeListOption(index: number, value: string) {
    this.args.targetAttribute.conditions.objectAt(index).controllingListOptionId = value;
  }

  @action controllingAttributeListOptionsItems(
    controllingDescriptorId: string,
    selectedValue: string,
  ) {
    if (!controllingDescriptorId || !this.args.allAttributes) {
      return [];
    }

    let selectedOption = this.args.allAttributes.findBy('id', controllingDescriptorId);
    if (!selectedOption) {
      return [];
    }
    let alreadyUsedOptions = this.args.targetAttribute.conditions
      .filterBy('controllingDescriptorId', controllingDescriptorId)
      .filterBy('isDeleted', false)
      .map((option: Condition) => option.controllingListOptionId);

    return selectedOption.listOptions
      .rejectBy('archived')
      .filter(
        (option: ListOption) =>
          option.id === selectedValue || !alreadyUsedOptions.includes(option.id),
      )
      .map((option: ListOption) => {
        return { text: option.label, value: option.id };
      });
  }

  @action onSelectionDependentAttributeListOptionsItems(index: number, value: string) {
    this.args.targetAttribute.conditions.objectAt(index).descriptorListOptionIds = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConversationAttributes::ConditionsSideDrawer': typeof ConditionsSideDrawer;
    'settings/conversation-attributes/conditions-side-drawer': typeof ConditionsSideDrawer;
  }
}
